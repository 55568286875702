'use strict';

import $ from 'jquery';

export default class Nav {
  constructor() {

    /***************
     ** NAVIGATION **
    ***************/

    var $navContainer = $('.site-menu-wrapper'),
    $dropDown = $('.dropdown', $navContainer);

    $dropDown.on('focusin', function () {
      $(this).addClass('is-open');
    }).on('focusout', function () {
      $(this).removeClass('is-open');
    });

    $dropDown.find('.dropdown-content .columns').matchHeight();

    let resizeTimeout;

    $(window).on('resize.alignDropdown', () => {

      clearTimeout(resizeTimeout);

      resizeTimeout = setTimeout(() => {
        $dropDown.each(function () {
          var $this = $(this).find('.dropdown-content'),
            dropdownWidth = $this.outerWidth(),
            dropdownPosX = $this.offset().left,
            screenWidth = $(window).width(),
            dropDownRightX = dropdownPosX + dropdownWidth;
          if (dropDownRightX >= screenWidth) {
            $this.addClass('align-right');
          } else {
            $this.removeClass('align-right');
          }
        });
      }, 150);

    }).trigger('resize.alignDropdown');

  }
}
