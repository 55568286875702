'use strict';

export default class Datepicker {
	constructor($selector) {

		$selector.on('change.datepicker', e => {
			if ($selector.val() !== '') {
				$selector.parents('.datepicker').addClass('focused');
			} else {
				$selector.parents('.datepicker').removeClass('focused');
			}
		}).trigger('change.datepicker');

	}
}
