// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import 'jquery-ui-dist';
import 'foundation-sites';
import objectFitImages from 'object-fit-images';
import 'slick-carousel';
import doT from 'dot';
import 'typeahead.js';
import Bloodhound from 'bloodhound-js';
import Emitter from 'tiny-emitter';
import 'jquery-match-height';


import MultiChoices from 'multichoices/multichoices';
import CustomSelect from 'custom-select/custom-select';
import Datepicker from 'datepicker/datepicker';
import Nav from 'molecules/nav/nav';
import DropdownLinks from 'molecules/dropdown-links/dropdown-links';
import TopAlert from 'molecules/top-alert/top-alert';
import NavMobile from 'molecules/nav-mobile/nav-mobile';
import Carousel from '../../../../_modules/molecules/carousel/carousel';
import AlertSection from '../../../../_modules/molecules/alert-section/alert-section';
import FilterBar from 'molecules/filter-bar/filter-bar';
// import Map from 'molecules/map/map';


import StickyAside from 'sticky-aside/sticky-aside';
import Footer from '../../../../_modules/organisms/footer/footer';
import Header from '../../../../_modules/organisms/header/header';

(($) => {
	window.emitter = new Emitter();

	$('.match-height-by-row').matchHeight({
		byRow: true
	});

	$('.match-height').matchHeight();

	$(document).foundation();




	$('.multichoices').map((i, ele) => {
		let $this = $(ele);

		let multiChoices = new MultiChoices($this);
	});

	$('.custom-select').map((i, ele) => {
		let $this = $(ele);

		let customSelect = new CustomSelect($this);
	});

	// Scroller for sidebar
	$(document).scroll(function() {
		if ($(window).width() > 1024) {
			var y = $(document).scrollTop(); //get page y value
			var $sidebar = $("#floating-career");

			var elementHeight = $('#floating-career').outerHeight() - 250;
			var parentHeight = $('.overview').outerHeight();
			var positionFixedMax = parentHeight - elementHeight;

			var positionFixed = 0;

			if (positionFixedMax > y) {
				positionFixed = 0;
			} else {
				positionFixed = positionFixedMax - y;
			}

			if (y >= 500) {
				$sidebar.css({
					position: "fixed",
					"top": positionFixed + 30
				});
			} else {
				$sidebar.css("position", "relative");
			}
		}
	});

	objectFitImages();

	// $("#startdate").datepicker();
	// $("#enddate").datepicker();
	// $("#datepicker").datepicker();

	// new Datepicker($('#startdate'));
	// new Datepicker($('#enddate'));

	new Nav();


	/********************
	 ** COMMON FUNCTION **
	 *********************/

	var addUrlParam = function (search, key, val) {
		var newParam = key + '=' + val,
			params = '?' + newParam;

		// If the "search" string exists, then build params from it
		if (search) {
			// Try to replace an existance instance
			params = search.replace(new RegExp('([?&])' + key + '[^&]*'), '$1' + newParam);

			// If nothing was replaced, then add the new param to the end
			if (params === search) {
				params += '&' + newParam;
			}
		}

		return params;
	};

	var queryString = function () {
		var query_string = {};
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			if (pair[0] == "") continue; //get rid of no query string
			// If first entry with this name
			if (typeof query_string[pair[0]] === "undefined") {
				query_string[pair[0]] = decodeURIComponent(pair[1]);
				// If second entry with this name
			} else if (typeof query_string[pair[0]] === "string") {
				var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
				query_string[pair[0]] = arr;
				// If third or later entry with this name
			} else {
				query_string[pair[0]].push(decodeURIComponent(pair[1]));
			}
		}
		return query_string;
	};

	var qString = queryString();



	/******************
	 ** PRESS RELEASE **
	 *******************/

	 if ($('.filter-bar').length) {
	 	new FilterBar($('.filter-bar'));
	 }

	// if ($('.filter-bar').length) {
	// 	var $form = $('.filter-bar'),
	// 		$keyword = $('.filter-bar__search input', $form),
	// 		$country = $('.search-by-country select', $form),
	// 		$category = $('input[type="checkbox"]', $form),
	// 		$startDate = $('#startdate', $form),
	// 		$endDate = $('#enddate', $form),
	// 		$type = $('input[type="checkbox"]', $('.filter-bar.filter-by-type')),
	// 		$newPath = '';

	// 	var getKeyValue = function ($obj) {
	// 		if ($obj.length) {
	// 			if ($obj.val()) {
	// 				if ($obj.val().trim() != '') {
	// 					return [$obj.prop('name'), $obj.val()];
	// 				} else {
	// 					return false;
	// 				}
	// 			} else {
	// 				return [$obj.prop('name'),''];
	// 			}
	// 		} else {
	// 			return false;
	// 		}
	// 	}

	// 	var getMultipleValue = function ($name) {
	// 		var arr = [];

	// 		if ($name.length) {
	// 			$name.each(function () {
	// 				if ($(this).prop('checked')) {
	// 					arr.push($(this).val());
	// 				}
	// 			});
	// 			return [$name.prop('name'), arr.join()];
	// 		} else {
	// 			return false
	// 		}
	// 	}

	// 	var $searchKeyword = $('.search-input', $('.search-results-page')),
	// 		keyword = null,
	// 		currentSearch;

	// 	if ($searchKeyword) {
	// 		currentSearch = qString[$searchKeyword.prop('name')];
	// 	}

	// 	$form.on('submit', function (e) {
	// 		e.preventDefault();

	// 		var currentUrl = window.location.href.split('?')[0],
	// 			newPath = '',
	// 			selectorArr = [$keyword, $country, $startDate, $endDate],
	// 			category = getMultipleValue($category);

	// 		$.each(selectorArr, function (index) {
	// 			if (selectorArr[index]) {
	// 				var value = getKeyValue(selectorArr[index]);

	// 				if (value) {
	// 					newPath = addUrlParam(newPath, value[0], value[1]);
	// 				}
	// 			}
	// 		});

	// 		if (category) {
	// 			newPath = addUrlParam(newPath, category[0], category[1]);
	// 		}

	// 		if ($type.length && currentSearch) {
	// 			var type = getMultipleValue($type);
	// 			newPath = '?keyword=' + currentSearch; //keep the current search keywords
	// 			if (type) {
	// 				newPath = addUrlParam(newPath, type[0], type[1]);
	// 			}
	// 		}

	// 		// console.log(newPath)

	// 		window.location.href = currentUrl + newPath;
	// 	});

	// 	// reset handler that clears the form
	// 	$('button[type="reset"]', $form).click(function (e) {
	// 		$(this).closest($form)
	// 			.find('[type="radio"],[type="checkbox"]').removeAttr('checked').end()
	// 			.find('textarea, [type="text"], [type="search"], option').val('').end()
	// 			.find('option').removeAttr('selected')
	// 			.trigger('submit');

	// 		$('.datepicker', $form).removeClass('focused');
	// 	});

	// }

	// $('.register-here').click(function (e) {
	// 	e.preventDefault();
	// 	var element_to_scroll_to = $('.register-for-this-event')[0];
	// 	element_to_scroll_to.scrollIntoView();
	// });

	// $('.home1').slick({
	// 	infinite: true,
	// 	speed: 300,
	// 	autoplay: true,
	// 	arrows: true,
	// 	prevArrow: '<img class="slick-left" src="/cwp/assets/MFA/img/slider-arrow-left.png" />',
	// 	nextArrow: '<img class="slick-right" src="/cwp/assets/MFA/img/slider-arrow-right.png" />',
	// 	slidesToShow: 1,
	// 	pauseOnHover: true,
	// 	dots: true
	// });

	new Carousel();

	$('.overseas2').slick({
		infinite: true,
		speed: 300,
		autoplay: true,
		slidesToShow: 1,
		adaptiveHeight: true,
		pauseOnHover: true,
		dots: true
	});

	if ($('.overseas-tile .arrow-right-list').length) {
		let $anchorList = $('.overseas-tile .arrow-right-list'),
		$anchorLink = $('a', $anchorList);

		let $mainHeader = $('.main-header');

		$anchorLink.map((i, ele) => {
			let $this = $(ele),
			target = $this.attr('href');

			$this.on('click', e => {
				e.preventDefault();
				let offset = (window.innerWidth <= 1140) ? $mainHeader.outerHeight() : 0;

				$('html, body').animate({
					scrollTop: $(target).offset().top - offset
				});
			});
		});
	}

	// $('.searchshow').hide();

	// $('.mobile-search').click(function () {
	// 	$('.searchshow').slideDown(300);
	// });

	// $('.searchshow .close').click(function () {
	// 	$('.searchshow').slideUp(300);
	// });

	$('.home2').tabs();
	$('.embassies2').tabs();
	$('.services').tabs();
	$("#inner-tab").tabs();
	$("#terms-tab").tabs();
	$("#foreign-tab").tabs();
	$('#mobile-terms').tabs();
	$('#accordion2').accordion();

	$(".ui-accordion").accordion({
		collapsible: true,
		heightStyle: "content"
	});

	$(".accordion").accordion({
		collapsible: true,
		heightStyle: "content"
	});

	$('.expand').on('click', function (e) {
		e.preventDefault();
		var $parent = $(this).closest('.space'),
			$content = $parent.find('.ui-accordion-content').slideDown();

			$parent.find('.ui-accordion-header').addClass('ui-state-active');

	});

	$('.collapse').on('click', function (e) {
		e.preventDefault();
		var $parent = $(this).closest('.space'),
			$content = $parent.find('.ui-accordion-content').slideUp();

			$parent.find('.ui-accordion-header').removeClass('ui-state-active');
	});

	var elements = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'li', 'a', 'label', '.font-size', 'input', 'button', '.date', 'span', 'figcaption', '.search-filter'];
	var plusCounter = 0;
	var minusCounter = 0;

	function decreaseFont() {
		if (minusCounter < 1) {
			for (var i = 0; i < elements.length; i++) {
				var element = elements[i];
				$('.body-content ' + element).each(function () {
					var fontSize = $(this).css('font-size');
					var res = parseInt(fontSize.substring(0, 2));
					if (res >= 10 && !$(this).hasClass('no-fresize')) {
						$(this).css('font-size', (res - 2) + 'px');
					}
				});
			}
			--plusCounter;
			++minusCounter;
		}
	}

	function increaseFont() {
		if (plusCounter < 1) {
			for (var i = 0; i < elements.length; i++) {
				var element = elements[i];
				$('.body-content ' + element).each(function () {
					var fontSize = $(this).css('font-size');
					var res = parseInt(fontSize.substring(0, 2));

					if (!$(this).hasClass('no-fresize')) {
						$(this).css('font-size', (res + 2) + 'px');
					}
				});
			}
			++plusCounter;
			--minusCounter;
		}
	}

	// function decreaseFont() {
	// 		if (minusCounter === 0) {
	// 				$('.body-content').css('font-size', 50 + '%');
	// 				--plusCounter;
	// 				++minusCounter;
	// 		} else if (minusCounter === -1) {
	// 				$(html).css('font-size', 100 + '%');
	// 				--plusCounter;
	// 				++minusCounter;
	// 		}
	// }

	// function increaseFont() {
	// 		if (plusCounter < 1) {
	// 				$('.body-content').css('font-size', 150 + '%');
	// 		}
	// 		--minusCounter;
	// 		++plusCounter;
	// }

	$('.decrease-font').on('click', function (e) {
		e.preventDefault();
		decreaseFont();
	});

	$('.increase-font').on('click', function (e) {
		e.preventDefault();
		increaseFont();
	});

	new NavMobile();


	// $('.gallery-row').slick({
	// 	infinite: true,
	// 	speed: 300,
	// 	autoplay: true,
	// 	arrows: true,
	// 	prevArrow: '<img class="slick-left" src="img/black-left-arrow.png" />',
	// 	nextArrow: '<img class="slick-right" src="img/black-right-arrow.png" />',
	// 	slidesToShow: 3,
	// 	adaptiveHeight: true,
	// 	responsive: [{
	// 			breakpoint: 1024,
	// 			settings: {
	// 				slidesToShow: 2
	// 			}
	// 		},
	// 		{
	// 			breakpoint: 668,
	// 			settings: {
	// 				slidesToShow: 1,
	// 			}
	// 		},
	// 	]
	// });

	// $('.fancybox-media').click(function (e) {
	// 	e.preventDefault();
	// });

	// /* FANCYBOX VIDEO */
	// $(".fancybox-media").fancybox({
	// 	openEffect: 'none',
	// 	closeEffect: 'none',
	// 	helpers: {
	// 		media: {},
	// 		overlay: {
	// 			locked: false
	// 		}
	// 	},
	// 	padding: 0,
	// 	margin: 0
	// });

	// $(".gallery-view").fancybox({
	// 	openEffect: 'none',
	// 	closeEffect: 'none',
	// 	helpers: {
	// 		media: {},
	// 		overlay: {
	// 			locked: false
	// 		}
	// 	},
	// 	padding: 0,
	// 	margin: 0
	// });

	$(document).on('click', '.list-view', function (e) {
		e.preventDefault();
		if ($(this).hasClass('active')) {} else {
			$(this).addClass('active');
			$('.map-view').removeClass('active');
			$('.map-view-content').hide();
			$('.map').hide();
			$('.list-view-content').show();
		}
	});

	$('.embassy-dropdown-content').hide();
	$('.map').hide();

	$(document).on('click', '.map-view', function (e) {
		e.preventDefault();
		if ($(this).hasClass('active')) {} else {
			$(this).addClass('active');
			$('.list-view').removeClass('active');
			$('.list-view-content').hide();
			$('.map-view-content').show();
			$('.map').show();
		}
	});

	// List Map 2

	$(document).on('click', '.list-view2', function (e) {
		e.preventDefault();
		if ($(this).hasClass('active')) {
			// console.log("Test");
		} else {
			$(this).addClass('active');
			$('.map-view2').removeClass('active');
			$('.map-view-content2').hide();
			$('.map2').hide();
			$('.list-view-content2').show();
		}
	});

	$('.map2').hide();

	$(document).on('click', '.map-view2', function (e) {
		e.preventDefault();
		if ($(this).hasClass('active')) {
			// console.log("Test 2");
		} else {
			$(this).addClass('active');
			$('.list-view2').removeClass('active');
			$('.list-view-content2').hide();
			$('.map-view-content2').show();
			$('.map2').show();
		}
	});

	/**************
	 ** EMBASSIES **
	 **************/

	var clickAccordionEmbassy = function () {
		$('.embassy-dropdown').off('click.accordion').on('click.accordion', function (e) {
			e.preventDefault();

			var $this = $(this),
				$content = $this.next('.embassy-dropdown-content');

			if ($this.hasClass('active')) {
				$this.removeClass('active');
				$content.slideUp();
				$this.find('.fa').removeClass('fa-minus').addClass('fa-plus');
			} else {
				$this.addClass('active');
				$content.slideDown();
				$this.find('.fa').removeClass('fa-plus').addClass('fa-minus');
			}
		});
	}

	clickAccordionEmbassy();

	/****************************************
	 ** EMBASSIES / WHERE ARE YOU TRAVELING **
	 *****************************************/
	var countryList = []

	var autoCompleteAjax = function ($selector, apiURL, isEmbassy) {
		var isEmbassy = isEmbassy || false,
			data = {
				'letter': ''
			};
		$.ajax({
				url: apiURL,
				method: 'post',
				dataType: 'json',
				contentType: 'application/json',
				data: JSON.stringify(data)
			})
			.done(function (data) {
				var tempList = [];

				if (isEmbassy) {
					$.each(data.Data, function (key, value) {
						var country = value.name,
							type = data.Type;

						value.country = country;
						tempList.push(value);

						if (type.toLowerCase() !== 'fr') {
							if (value['child'].length) {
								$.each(value['child'], function (childKey, childValue) {
									childValue.country = country;
									tempList.push(childValue);
								});
							}
						}
					})

					countryList = tempList.map(function (el) {
						return {
							name: el.name,
							country: el.country,
							keywords: el.keywords
						};
					});
					// console.log(countryList)
				} else {
					$.each(data.locationList, function (key, value) {
						var country = value.name;
						value.country = country;
						tempList.push(value);
					})

					countryList = tempList.map(function (el) {
						return {
							name: el.name,
							country: el.name,
							keywords: el.keywords,
							link: el.link
						};
					});
				}

				var title = new Bloodhound({
					datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name', 'country', 'keywords'),
					queryTokenizer: Bloodhound.tokenizers.whitespace,
					identify: function (obj) {
						return obj.name;
					},
					local: countryList
				});

				function getTitleWithDefault(q, sync) {
					if (q === '') {
						sync(title.get(arrTitle));
					} else {
						title.search(q, sync);
					}
				}

				title.initialize();

				$selector.typeahead({
					highlight: true
				}, {
					name: 'country',
					displayKey: 'country',
					limit: 50,
					source: getTitleWithDefault,
					templates: {
						suggestion: function (data) {
							if (data.name == data.country) {
								return '<div class="tt-suggestion tt-selectable">' + data.name + '</div>'
							} else {
								return '<div class="tt-suggestion tt-selectable">' + data.name + ', ' + data.country + '</div>'
							}
						}
					}
				})

				if (!isEmbassy) {
					$selector.on('typeahead:select', function (ev, suggestion) {
						$(this).data('link', suggestion.link);
						window.location.href = suggestion.link;
					});
				}

				// Fix HTML Validation of div and pre inside span element
				// if($('.twitter-typeahead').length) {
				// 	$('.twitter-typeahead').map((i,ele)=> {
				// 		var $this = $(ele);
			
				// 		$this.replaceWith('<div class="twitter-typeahead" style="position: relative; display: inline-block;">' + $this.html() +'</div>')
				// 	});
				// }
			});
	}

	var autoComplete = function ($selector, apiURL, filter, isEmbassy) {
		var filter = filter || false,
      isEmbassy = isEmbassy || false;

		$selector
			.on('keypress', function (e) {
				if (e.keyCode == 13 || e.which == 13) {
					e.preventDefault();
				}
			})
			.on('keyup', function (e) {
				var value = $(this).val(),
					newPath = '',
					dataFilter = {
						'letter': '',
						'keywords': value.trim()
					};

				newPath = addUrlParam(newPath, 'keywords', value.trim());

				if (e.keyCode == 13 || e.which == 13) {
          e.preventDefault();
          let disallowedCharacter = /^[\w\-\s]+$/;
          if (!disallowedCharacter.test(value)) {
            if (filter) {
              filterContent(dataFilter);
              history.pushState(dataFilter, null, currentUrl + newPath);
            } else {
              var selectables = $selector.siblings('.tt-menu').find('.tt-selectable');
              if (selectables.length > 0) {
                if ($selector.data('link')) {
                  window.location.href = $selector.data('link');
                } else {
                  $(selectables[0]).trigger('click');
                  $selector.typeahead('close');
                  return false;
                }
              } else {
                window.location.href = $selector.closest('form').attr('action') + '?error=' + value.trim();
              }
            }
          }
				}
			});

		autoCompleteAjax($selector, apiURL, isEmbassy);
	}

	var $embassies = $('.embassies-container'),
		$countries = $('.countries-container'),
		isEmbassy = false,
		isCountry = false,
		$container = '',
		$template = '',
		$listWrap = '',
		locationMarkers = new Array(),
		infoWindowList = new Array();

	if ($embassies.length || $countries.length) {

		if ($embassies.length) {
			$container = $embassies;
			$template = $('#embassiesList');
			$listWrap = $('.embassies-list', $container);
			isEmbassy = true;
		} else if ($countries.length) {
			$container = $countries;
			$template = $('#countriesList');
			$listWrap = $('.countries-list', $container);
            isCountry = true;
		}

		var apiURL = $container.data('api') || '',
			type = $container.data('type') || '',
			currentUrl = window.location.href.split('?')[0],
			$jumpBtn = $('.js-jumpto', $container);

		// FILTERING

		var renderListing = function (data) {
			var listTemplate = doT.template($template.html()),
				_renderedHTML = listTemplate(data);

			$listWrap.empty().append(_renderedHTML);

			if (isEmbassy) {
				clickAccordionEmbassy();
			}
		};

		var filterContent = function (dataFilter) {
			// console.log(dataFilter)
			$.ajax({
					url: apiURL,
					method: 'POST',
					dataType: 'json',
					contentType: 'application/json',
					data: JSON.stringify(dataFilter)
				})
				.done(function (data) {
                    renderListing(data);

                    if($countries.length) {
                        let $countryList = $('.countries-list', $countries);
                        let $columns = $('.columns', $countryList);
                        let $embassyDropdown = $('.embassy-dropdown', $columns);

                        $embassyDropdown.matchHeight({
                            byRow: false
                        });
                    }
				});
		}

		$jumpBtn.on('click', function (e) {
			e.preventDefault();

			var $this = $(this),
				letter = $this.data('letter') || '',
				newPath = '',
				dataFilter = {
					'letter': letter
				};

			$jumpBtn.removeClass('active');
			$this.addClass('active');

			newPath = addUrlParam(newPath, 'letter', dataFilter.letter);

			history.pushState(dataFilter, null, currentUrl + newPath);
			filterContent(dataFilter);
		});

		// AUTOCOMPLETE

		if (isEmbassy) {
			var $autoCompleteField = $('.search-input', $container);

			autoComplete($autoCompleteField, apiURL, true, true);

			$('button[type="submit"]', $container).on('click', function (e) {
				e.preventDefault();

				var value = $autoCompleteField.val(),
					newPath = '',
					dataFilter = {
						'letter': '',
						'keywords': value.trim()
					};

				newPath = addUrlParam(newPath, 'keywords', value.trim());

				filterContent(dataFilter);
				history.pushState(dataFilter, null, currentUrl + newPath);
			});
		}

		// ON LOAD

		var letterOnLoad = qString['letter'],
			dataFilterOnLoad = {
				'letter': 'a',
				'keywords': ''
			}

		if (letterOnLoad) {
			var dataFilterOnLoad = {
				'letter': letterOnLoad,
				'keywords': ''
			}
		} else {
			letterOnLoad = 'a';
		}

		filterContent(dataFilterOnLoad);
		$jumpBtn.removeClass('active');
		$('.js-jumpto[data-letter="' + letterOnLoad + '"]', $container).addClass('active');

		// MAP

		var $map = $('.google-map'),
			$mapAPI = $map.data('api'),
			isDraggable = true;

		if ($map.length) {
			window.initializeMap = function () {
				var gMap,
					mapOptions,
					letter = {
						'letter': ''
					}; // api is expecting the script to pass it a letter

				var mapStyles = [{
						elementType: "labels",
						stylers: [{
							"visibility": "off"
						}]
					}];

				$.ajax({
						url: $mapAPI,
						method: 'post',
						dataType: 'json',
						data: JSON.stringify(letter),
						contentType: 'application/json'
					})
					.done(function (mapData) {
						mapOptions = {
							draggable: isDraggable,
							center: mapData.mapOptions.center,
							zoom: mapData.mapOptions.zoom,
							scrollwheel: mapData.mapOptions.scrollwheel,
							mapTypeControl: mapData.mapOptions.mapTypeControl,
							mapTypeId: mapData.mapOptions.mapTypeId,
							streetViewControl: false,
							gestureHandling: 'greedy',
							styles         : mapStyles,
							panControlOptions: {
								position: google.maps.ControlPosition.LEFT_BOTTOM
							},
							zoomControlOptions: {
								position: google.maps.ControlPosition.LEFT_BOTTOM
							},
							streetViewControlOptions: {
								position: google.maps.ControlPosition.LEFT_BOTTOM
							}
						};

						gMap = new google.maps.Map(document.getElementById('map'), mapOptions);

						window.mapData = mapData;

						setupLocationMarkers(gMap);
					})
					.fail(function (jqXHR, textStatus, errorThrown) {
						console.table(['Fail to get map data', jqXHR, textStatus, errorThrown])
					});
			}

			var createInfoWindow = function (markerContent) {
				var title = markerContent.name,
					link = markerContent.link,
					text = markerContent.advisory.replace('\n', '<br/>');

				var contentString = '<div class="infowindow"><a href="' + link + '" class="infowindow-title">' + title + '<i class="fa fa-external-link"></i></a>';

				if (text) {
					text = text.replace('&lt;', '<');
					text = text.replace('&gt;', '>');
					contentString += '<p class="infowindow-text">' + text + '</p>';
				}

				contentString += '</div>';

				var infowindow = new google.maps.InfoWindow({
					content: contentString,
					maxWidth: 250
				});

				return infowindow;
			};

			function closeAllInfoWindows() {
				for (var i = 0; i < infoWindowList.length; i++) {
					infoWindowList[i].close();
				}
			}

			var createLocationMarkers = function (data, map, i) {

				var position = new google.maps.LatLng(data.latitude, data.longitude),
					title = data.advisory,
					marker = data.pin;

				var icon = {
					url: marker,
					size: new google.maps.Size(28, 40), // the original size
					origin: new google.maps.Point(0, 0), // position in the sprite
					anchor: new google.maps.Point(14, 40),
					index: 1,
					labelOrigin: new google.maps.Point(14, 50)
				};

				var marker = new google.maps.Marker({
					position: position,
					map: map, // google.maps.Map
					title: title,
					label: {
						text: data.name,
						fontWeight: 'bold'
					},
					icon: icon
				});

				var infowindow = createInfoWindow(data);

				infoWindowList.push(infowindow);

				google.maps.event.addListener(marker, 'click', function () {
					closeAllInfoWindows();
					infowindow.open(map, marker);
					map.panTo(marker.getPosition());
				});

				locationMarkers.push(marker);
			}

			var setupLocationMarkers = function (map) {
				var locationList = mapData.locationList;
				for (var i = 0; i < locationList.length; i++) {
					createLocationMarkers(locationList[i], map, i);
				}
				google.maps.event.trigger(map, 'resize')
			};

			var setupMap = function () {
				var script = document.createElement('script'),
					scriptStr = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBwMNk8Qf0zxpmtIDt9dGc1lVHb-COMHLs&v=3.exp&callback=initializeMap&language=en&region=US';

				script.type = 'text/javascript';
				script.src = scriptStr;
				document.body.appendChild(script);
			};

			setupMap();
		}
	}

	var $travelling = $('.travel-form');

	if ($('.country-form').length) {
		$travelling = $('.country-form');
	}

	if ($travelling.length) {
		var $container = $travelling,
			apiURL = $container.data('api') || '',
			currentUrl = window.location.href.split('?')[0];

		// AUTOCOMPLETE

		var $autoCompleteField = $('.search-input', $container);

		autoComplete($autoCompleteField, apiURL, false);

		$('button[type="submit"]', $container).on('click', function (e) {
			e.preventDefault();
			var value = $autoCompleteField.val(),
				newPath = '',
				dataFilter = {
					'letter': '',
					'keywords': value.trim()
				};

			if (value) {
        e.preventDefault();

        let disallowedCharacter = /^[\w\-\s]+$/;
        if (!disallowedCharacter.test(value)) {
          var link = $('.search-input.tt-input', $container).data('link');
          if (link) {
            window.location.href = link;
          } else {
            window.location.href = $travelling.attr('action') + '?error=' + value.trim();
          }
        }
			}
		});
	}

	$(document).on('click', '.eregister-submit', function(e) {
		e.preventDefault();

		$('.error-list').html('');

		$('.eregister input').each(function(e) {
				$(this).removeClass('red-warning');
		});

		$('.eregister textarea').each(function(e) {
				$(this).removeClass('red-warning');
		});

		$('.eregister input').each(function(e) {
				if ( $(this).hasClass('required-field') && $(this).val() == "" ) {
						$(this).addClass('red-warning');
						// var name = $(this).attr('name');
						var name = $(this).parent().parent().children('.columns').children('label').html();
						name = name.slice(0, -1);
		  $(this).parent().children('.error-field').html(name + ' is a required field.');
				}
		});

		$('.eregister textarea').each(function(e) {
				if ( $(this).hasClass('required-field') && $(this).val() == "" ) {
						$(this).addClass('red-warning');
						// var name = $(this).attr('name');
						var name = $(this).parent().parent().children('.columns').children('label').html();
						name = name.slice(0, -1);
		  $(this).parent().children('.error-field').html(name + ' is a required field.');
				}
		});
	});

	// $('#expanding-search').focus(function () {
	// 	$(this).addClass('expanded');
	// 	$('.font-size').fadeOut(300);
	// }).blur(function () {
	// 	$(this).removeClass('expanded');
	// 	$('.font-size').fadeIn(300);
	// });

	$('#country-search').focus(function () {
		$(this).addClass('expanded');
		$('.font-size').fadeOut(300);
	}).blur(function () {
		$(this).removeClass('expanded');
		$('.font-size').fadeIn(300);
	});

	// Search keyword
	$(document).on('click', '.search-keyword', function (e) {
		e.preventDefault();

		$('.filter-category').removeClass('active');
		$('.filter-date').removeClass('active');

		$('.con-details').each(function () {
			$(this).hide();
		});

		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(this).parent().children('.con-details').hide();
		} else {
			$(this).addClass('active');
			$(this).parent().children('.con-details').show();
		}
	});

	// Filter Category
	$(document).on('click', '.filter-category', function (e) {
		e.preventDefault();

		$('.search-keyword').removeClass('active');
		$('.filter-date').removeClass('active');

		$('.con-details').each(function () {
			$(this).hide();
		});

		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(this).parent().children('.con-details').hide();
		} else {
			$(this).addClass('active');
			$(this).parent().children('.con-details').show();
		}
	});

	// Filter Date
	$(document).on('click', '.filter-date', function (e) {
		e.preventDefault();

		$('.search-keyword').removeClass('active');
		$('.filter-category').removeClass('active');

		$('.con-details').each(function () {
			$(this).hide();
		});

		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(this).parent().children('.con-details').hide();
		} else {
			$(this).addClass('active');
			$(this).parent().children('.con-details').show();
		}
	});

	//remove the menu with color red or with class pagelinks
	$("a.pagelink").parent().remove();

	// $(document).on('click', '.org-chart .panel a', function (e) {
	// 	e.preventDefault();
	// 	if ($(this).hasClass('active')) {
	// 		$(this).removeClass('active');
	// 		$(this).parent().children('.panel-list').slideUp(300);
	// 	} else {
	// 		$(this).addClass('active');
	// 		$(this).parent().children('.panel-list').slideDown(300);
	// 	}
	// });

	$('.tabs').tabs();

	var active = '.overview #terms-tab .terms-container ul .active';
	$('.overview #terms-tab .terms-container ul li').on('click', function () {
		$('.overview #terms-tab .terms-container ul li.active').removeClass('active');
		$(this).addClass('active');

		/*if($(active).hasClass("active")){
			var source = $(active).find("a").children('img').attr('src');
			var newSource = source.substr(0, 9);
			$(active).find("a").children('img').attr('src', newSource + 'i.png');
		}*/
	});

	var activeMobile = '.terms-mobile #mobile-terms ul .activeMobile';
	$('.terms-mobile #mobile-terms ul li').on('click', function () {
		$('.terms-mobile #mobile-terms ul li.activeMobile').removeClass('activeMobile');
		$(this).addClass('activeMobile');
	});

	var addPaddingFunc = function(){
		var headerHeight = $('.main-header').outerHeight(),
			paddingTop = headerHeight;

		// if ($('.top-alert').length) {
		// 	paddingTop += 10;
		// }

		$('.body-content').css('padding-top', paddingTop + 'px');
	}

	addPaddingFunc();

	var resizeTimer;

	$(window).on('resize', function(e) {

		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function() {
			addPaddingFunc();
		}, 250);

	});

	window.emitter.on('alertClose', () => {
		addPaddingFunc();
	});

	new AlertSection();

	if ($('.sticky-aside').length) {
		new StickyAside();
	}

	if ($('.dropdown-links').length) {
		new DropdownLinks();
	}

	if ($('.top-alert').length) {
		new TopAlert();
  }

  // TO FIXED: remove this when start working the validation
  $('.invalid-error-msg').each(function() {
    let $this = $(this);
    let $input = $this.parent().find('input');

    let disallowedCharacter = /^[\w\-\s]+$/;

    $input.on('input', e => {
			if ($input.val() !== "") {
				if (!disallowedCharacter.test($input.val())) {
					$this.show();
					if($input.val() !== '') {
						$this.parent().addClass('has-error');
					}
				} else {
					$this.hide();
					$this.parent().removeClass('has-error');
				}
			} else {
				$this.hide();
				$this.parent().removeClass('has-error');
			}
    });

    $input.on('keypress', e => {
      if (e.which == 13 || e.keyCode == 13) {
        if (!disallowedCharacter.test($input.val())) {
          e.preventDefault();
        }
      }
    })
	});

	// if($('.map-container').length) {
	// 	new Map();
	// }

	new Header();
	new Footer();

	// add smooth scrolling to either name or id
	let scrollToHash = function(hash) {
		let name = hash.substring(1, hash.length);

		if ($(hash).length) {

			$('html, body').animate({
					scrollTop: $(hash).offset().top - 200
			}, 900, 'swing');

		} else if($('[name="' + name +'"]')) {

			$('html, body').animate({
				scrollTop: $('[name="' + name +'"]').offset().top - 200
			}, 900, 'swing');

		}
	}

	// add smooth scrolling on load
	if (window.location.hash) {
		var hash = window.location.hash;

		scrollToHash(hash);
	}

	// add smooth scrolling on click
	$(document).on('click', '.body-content a[href^="#"]', function (e) {
		e.preventDefault();

		var $this = $(this),
				hash = $this.attr('href');

		scrollToHash(hash);

	});

	$('meta[class*=\'foundation\']').remove();
	$('.ui-tabs-anchor').removeAttr('role').removeAttr('tabindex');
})($);
