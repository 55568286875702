'use strict';

import $ from 'jquery';

export default class TopAlert {
	constructor() {

		let $topAlert = $('.top-alert'),
		$btnClose = $('.btn-close', $topAlert);

		$btnClose.on('click', e => {
			e.preventDefault();

			$topAlert.slideUp(300, () => {
				window.emitter.emit('alertClose');
			});

			sessionStorage['alertclose'] = true;
		});

		if (typeof sessionStorage['alertclose'] === 'undefined') {
			$topAlert.slideDown(300, () => {
				window.emitter.emit('alertClose');
			});
		}
	}
}
