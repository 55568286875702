'use strict';

import Datepicker from 'datepicker/datepicker';

export default class FilterBar {
	constructor($selector) {
		var addUrlParam = function (search, key, val) {
			var newParam = key + '=' + val,
				params = '?' + newParam;

			// If the "search" string exists, then build params from it
			if (search) {
				// Try to replace an existance instance
				params = search.replace(new RegExp('([?&])' + key + '[^&]*'), '$1' + newParam);

				// If nothing was replaced, then add the new param to the end
				if (params === search) {
					params += '&' + newParam;
				}
			}

			return params;
		};

		var queryString = function () {
			var query_string = {};
			var query = window.location.search.substring(1);
			var vars = query.split("&");
			for (var i = 0; i < vars.length; i++) {
				var pair = vars[i].split("=");
				if (pair[0] == "") continue; //get rid of no query string
				// If first entry with this name
				if (typeof query_string[pair[0]] === "undefined") {
					query_string[pair[0]] = decodeURIComponent(pair[1]);
					// If second entry with this name
				} else if (typeof query_string[pair[0]] === "string") {
					var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
					query_string[pair[0]] = arr;
					// If third or later entry with this name
				} else {
					query_string[pair[0]].push(decodeURIComponent(pair[1]));
				}
			}
			return query_string;
		};

		var qString = queryString();

		$('#startdate').datepicker({
			changeMonth: true,
			changeYear: true,
			minDate: ('-100Y'),
			maxDate: new Date(),
			beforeShow: function (input, inst) {
				setTimeout(function(){
					$(inst.dpDiv).css({
						top: $(input).offset().top + $(input).outerHeight(),
						left: $(input).offset().left
					});
				}, 0)


    	}
		});

		$('#enddate').datepicker({
			changeMonth: true,
			changeYear: true,
			minDate: ('-100Y'),
			maxDate: new Date(),
			beforeShow: function(input,inst){
				setTimeout(function(){
					$(inst.dpDiv).css({
						top: $(input).offset().top + $(input).outerHeight(),
						left: $(input).offset().left
					});
				}, 0)
			}
		});

		new Datepicker($('#startdate'));
		new Datepicker($('#enddate'));

		let startDate, endDate;

		$('#startdate').on('change', e => {
			startDate = $('#startdate').datepicker('getDate');

			$('#enddate').datepicker('option', 'minDate', startDate);
		});

		$('#enddate').on('change', e => {
			endDate = $('#enddate').datepicker('getDate');

			$('#startdate').datepicker('option', 'maxDate', endDate);
		});

		var $form = $selector,
			$keyword = $('.filter-bar__search input', $form),
			$country = $('.filter-bar__country select', $form),
			$category = $('.filter-bar__combo input[type="checkbox"]', $form),
			$startDate = $('#startdate', $form),
			$endDate = $('#enddate', $form),
			$type = $('input[type="checkbox"]', $('.filter-bar.filter-by-type')),
			$newPath = '';

		var getKeyValue = function ($obj) {
			if ($obj.length) {
				if ($obj.val()) {
					if ($obj.val().trim() != '') {
						return [$obj.prop('name'), $obj.val()];
					} else {
						return false;
					}
				} else {
					return [$obj.prop('name'),''];
				}
			} else {
				return false;
			}
		}

		var getMultipleValue = function ($name) {
			var arr = [];

			if ($name.length) {
				$name.each(function () {
					if ($(this).prop('checked')) {
						arr.push($(this).val());
					}
				});
				return [$name.prop('name'), arr.join()];
			} else {
				return false
			}
		}

		var $searchKeyword = $('.search-input', $('.search-results-page')),
			keyword = null;

		// Keyword form in search result page
		if ($searchKeyword.length > 0) {
			$searchKeyword.closest('form').on('submit', e => {
				e.preventDefault();

				var currentUrl = window.location.href.split('?')[0],
					newPath = '';

				newPath = '?keyword=' + $searchKeyword.val();

				var type = getMultipleValue($type);
				if (type) {
					newPath = addUrlParam(newPath, type[0], type[1]);
				}
				window.location.href = currentUrl + newPath;
			});
		}

		$form.on('submit', function (e) {
			e.preventDefault();

			var currentUrl = window.location.href.split('?')[0],
				newPath = '',
				selectorArr = [$keyword, $country, $startDate, $endDate],
				category = getMultipleValue($category);

			$.each(selectorArr, function (index) {
				if (selectorArr[index]) {
					var value = getKeyValue(selectorArr[index]);
					if (value) {
						newPath = addUrlParam(newPath, value[0], value[1]);
					}
				}
			});

			if (category) {
				newPath = addUrlParam(newPath, category[0], category[1]);
			}

			// only for search results page
			if ($type.length > 0 && $searchKeyword.length > 0) {
				newPath = '?keyword=' + $searchKeyword.val();
				var type = getMultipleValue($type);
				if (type) {
					newPath = addUrlParam(newPath, type[0], type[1]);
				}
			}

			window.location.href = currentUrl + newPath;
		});

		// reset handler that clears the form
		$('button[type="reset"]', $form).on('click', function (e) {
			$(this).closest('.filter-bar')
				.find('[type="radio"],[type="checkbox"]').removeAttr('checked').end()
				.find('textarea, [type="text"], [type="search"], option, .datepicker input').val('').end()
				.find('option').removeAttr('selected')
			$(this).closest('.filter-bar').trigger('submit');

			$('.datepicker', $form).removeClass('focused');
		});

	}
}
