'use strict';

import $ from 'jquery';


export default class AlertSection {
	constructor() {
		let $alertSection = $('.alert-section');

		$alertSection.map((i, ele) => {
			let $this = $(ele);

			if ($this.find('.link-expand').length > 0) {
				let $linkExpand = $('.link-expand', $this),
				lessLabel = $linkExpand.find('span').data('label-less'),
				moreLabel = $linkExpand.find('span').data('label-more'),
				$content = $this.find('.alert-section__content');

				// user clicks
				$linkExpand.on('click', (e) => {
					e.preventDefault();

					if ($linkExpand.hasClass('expanded')) {
						$content.stop().slideUp(300, () => {
							$content.removeClass('expanded');
							$linkExpand.find('span').text(moreLabel);
						});
						$linkExpand.removeClass('expanded');
					} else {
						$content.stop().slideDown(300, () => {
							$content.addClass('expanded');
							$linkExpand.find('span').text(lessLabel);
						});
						$linkExpand.addClass('expanded');
					}
				});

				// Only for pages with id="country-detail"
				if ($alertSection.parent().is('#country-detail')) {
					console.log('yes')
					// Expand only for the 1st alert-section of the page
					//if (i === 0) {
						$content.addClass('expanded');
						$linkExpand.find('span').text(lessLabel);
						$linkExpand.addClass('expanded');
					//}
				}
			}
		});
	}
}
