'use strict';

export default class DropdownLinks {
	constructor() {
		let $dropdownLinks = $('.dropdown-links');

		$dropdownLinks.map((i, ele) => {
			let $this = $(ele);

			let $label = $('.dropdown-links__label', $this);

			$label.on('click', e => {
				if ($this.hasClass('expanded')) {
					$this.removeClass('expanded');
				} else {
					$this.addClass('expanded');
				}
			});
		});

		$(document).on('click', e => {
			if (!$(e.target).hasClass('.dropdown-links') && !$(e.target).parents('.dropdown-links').length) {
				$dropdownLinks.removeClass('expanded');
			}
		});
	}
}
