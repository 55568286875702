'use strict';

import $ from 'jquery';

export default class NavMobile {
	constructor() {

		var $mobileSubmenu = $('.menu.vertical .submenu'),
		$btnSubmenu = $mobileSubmenu.children('.btn-arrow');

		var updateHeightBtn = function($submenu){

			var $directChildLink = $submenu.children('a'),
				height = $directChildLink.outerHeight();

			$directChildLink.next('.btn-arrow').css('height', height);
		}

		$mobileSubmenu.each(function(){
			var $this = $(this);
			if ($this.find('.subcontent')) {
				$this.addClass('has-child');
				updateHeightBtn($this);
			};
		});

		$btnSubmenu.on('click', function (e) {
			e.preventDefault();
			var $this = $(this),
				$parent = $this.parent(),
				$siblings = $parent.siblings('li.submenu.active'),
				$subcontent = $parent.children('.subcontent');
			if ($parent.hasClass('active')) {
				$parent.removeClass('active');
				$subcontent.slideUp(300);
			} else {
				$parent.addClass('active');
				$subcontent.slideDown(300);
				$subcontent.children('.submenu').each(function(){
					updateHeightBtn($(this));
				});
				$siblings.children('.subcontent').slideUp(300, () => {
					$siblings.removeClass('active')
				});
			}
		});

		$('.mobile-search').on('click', () => {

			if ($('.searchshow').hasClass('opened')) {
				this.closeSearchBar();

			} else {
				this.openSearchBar();

			}

		});

		$(document).on('click', e => {
			let $target = $(e.target);
			if (!$target.hasClass('searchshow') && !$target.parents('.searchshow').length && !$target.parents('.search-button').length && !$target.hasClass('search-button')) {
				this.closeSearchBar();
			}
		})

		// $('.searchshow .close').click(function () {
		// 	$('.searchshow').slideUp(300);
		// });
	}

	closeSearchBar() {
		$('.searchshow').slideUp(300);
		$('.searchshow').removeClass('opened');
		$('.mobile-search').find('.fa').removeClass('fa-close').addClass('fa-search');
	}

	openSearchBar() {
		$('.searchshow').slideDown(300);
		$('.searchshow').addClass('opened');
		$('.mobile-search').find('.fa').removeClass('fa-search').addClass('fa-close');
	}
}
