'use strict';

import 'slick-carousel';

export default class Carousel {
  constructor() {

  	$('.carousel-slider').on('init', () => {
  		$('.carousel-slider .slick-slide').matchHeight();
  	});

    $('.carousel-slider').slick({
			infinite: true,
			speed: 300,
			autoplay:true,
			autoplaySpeed: 3000,
			arrows: true,
			prevArrow: $('.arrow-left'),
			nextArrow: $('.arrow-right'),
			appendDots: $('.slider-control'),
			slidesToShow: 1,
			pauseOnHover: true,
			dots: false
			// adaptiveHeight: true
		});
  }
}
