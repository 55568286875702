'use strict';
import $ from 'jquery';

export default class Multichoices {
	constructor($selector) {

		let $list = $selector.find('.multichoices-list'),
		$label = $selector.find('.multichoices-label'),
		$number = $selector.find('.multichoices-label__number'),
		$inputs = $list.find('input');

		let noOfChoices = $list.find('li').length,
		noOfSelectedChoices = $list.find('input:checked').length;

		this.$selector = $selector;

		this.noOfChoices = noOfChoices;

		this.$number = $number;

		this.isExpanded = false;

		this.updateNumberLabel(noOfSelectedChoices);

		$label.on('click', e => {
			if (this.isExpanded) {
				this.hideList();
			} else {

				this.showList();
			}
		});

		$inputs.on('change', () => {
			noOfSelectedChoices = $list.find('input:checked').length;
			this.updateNumberLabel(noOfSelectedChoices);
		});


		$(document).on('click', e => {
			if (!$(e.target).hasClass('multichoices') && !$(e.target).parents('.multichoices').length) {
				this.hideList();
			}
		});

	}

	updateNumberLabel(noOfSelectedChoices) {
		if (noOfSelectedChoices > 0) {
			this.$number.text(`${noOfSelectedChoices} of ${this.noOfChoices} selected`);
		} else {
			this.$number.text('press to select');
		}
	}

	showList() {
		this.$selector.addClass('expanded');
		this.isExpanded = true;
	}

	hideList() {
		this.$selector.removeClass('expanded');
		this.isExpanded = false;
	}
}
