'use strict';

import $ from 'jquery';

export default class StickyAside {
	constructor() {

		let $window = $(window),
		$stickyAside = $('.sticky-aside');

		let stickyOrigPos = $stickyAside.parent().offset().top;

		let minScreenWidth = ($('.career-inner-overview').length > 0) ? 768 : 1024;

		$window.on('resize.stickyAside', () => {

			// Taken from the parent (columns) - 30px padding
			let stickyOrigWidth = $stickyAside.parent().outerWidth() - 30;
			stickyOrigPos = $stickyAside.parent().offset().top;

			if ($window.width() > minScreenWidth && $window.height() > $stickyAside.outerHeight()) {
				if ($stickyAside.outerHeight() < $stickyAside.parent().siblings('.large-8').outerHeight()) {

					$window.off('scroll.stickyAside');

					$window.on('scroll.stickyAside', () => {

						let $mainHeader = $('.main-header'),
						offsetTop = 0,
						windowTop = $window.scrollTop();

						// if ($window.width() > minScreenWidth) {

						if ($mainHeader.css('position') == 'fixed') {
							offsetTop = $mainHeader.outerHeight();
						}

						if (windowTop + offsetTop >= stickyOrigPos) {
							if (windowTop + offsetTop + $stickyAside.outerHeight() >= $('.main-footer').offset().top - 20) {
								$stickyAside.addClass('is-bottom');
								$stickyAside.css({
									"top": "auto",
									"width": stickyOrigWidth
								})
							} else {
								$stickyAside.removeClass('is-bottom');
								$stickyAside.addClass('is-sticky');
								$stickyAside.css({
									"top": offsetTop,
									"width": stickyOrigWidth
								});
							}
						} else {
							$stickyAside.removeClass('is-sticky');
							$stickyAside.removeClass('is-bottom');
							$stickyAside.css({
								"top": "auto",
								"width": "auto"
							})
						}
					}).trigger('scroll.stickyAside');
				}

			} else {
				$window.off('scroll.stickyAside');
				$stickyAside.removeClass('is-sticky');
				$stickyAside.removeClass('is-bottom');
				$stickyAside.css({
					"width": "auto",
					"top": "auto"
				})
			}

		}).trigger('resize.stickyAside');

	}
}
