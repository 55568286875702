'use strict';

import $ from 'jquery';

export default class CustomSelect {
	constructor($selector) {

		let $icon = $('<i class="fa fa-angle-down"></i>'),
		$label = $('<span class="custom-select-label"></span>'),
		$value = $('<span class="custom-select-value"></span>'),
		$wrapper = $('<div class="custom-select-wrapper"></div>');

		$selector.wrap($wrapper);

		$label.append($value);
		$label.append($icon);

		$selector.before($label)

		$selector.on('change.customSelect', () => {
			$value.text($selector.find('option:selected').text());
		}).trigger('change.customSelect');

	}
}
