'use strict';

export default class Header {
	constructor() {

		$(document).ready(function(){
			let headerHeight = $('.main-header').height();
			let windowHeight = $(window).height();

			if ($('.top-alert').length) {
				headerHeight = headerHeight - $('.top-alert').height();
			}

			$('#offCanvas').css('padding-top', headerHeight);
			$('#offCanvas').css('max-height', `${windowHeight}px`);

		});

		$(window).resize(function(){
			let headerHeight = $('.main-header').height();
			let windowHeight = $(window).height();

			if ($('.top-alert').length) {
				headerHeight = headerHeight - $('.top-alert').height();
			}
			$('#offCanvas').css('padding-top', headerHeight);
			$('#offCanvas').css('max-height', `${windowHeight}px`);
		})



	}
}
