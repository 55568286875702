'use strict';

import $ from 'jquery';

export default class Footer {
  constructor() {
    let $siteFooter = $('.site-footer');
    let $scrollTopBtn = $('.btn-scroll-top', $siteFooter);

    $scrollTopBtn.on('click', e => {
      e.preventDefault();

      $('html, body').animate({
        scrollTop: 0
      });

    });

    $(window).on('scroll.scrollTopBtn', e => {
      let screenPos = $(window).scrollTop() + $(window).outerHeight();
      if ($(window).scrollTop() >= $('.site-header').outerHeight()) {
        $scrollTopBtn.addClass('shown');
      } else {
        $scrollTopBtn.removeClass('shown');
      }
      if (screenPos >= $siteFooter.offset().top + 45) {
        $scrollTopBtn.removeClass('sticky');
      } else {
        $scrollTopBtn.addClass('sticky');
      }
    }).trigger('scroll.scrollTopBtn');
  }
}
